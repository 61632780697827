import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Image,
  Input,
  Select,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import FooterItem from "../../components/FooterItem";
import surgeryIcon2 from "../../img/healthcheck2.jpeg";
import cardImg from "../../img/card-img.jpg";
import { useNavigate } from "react-router-dom";
import { ECHANNELLING, ApiEndpoints } from "../../constants/ApiCunstants";
import { Methods } from "../../constants";
import { useLocation } from "react-router-dom";

function OnlinePaymentDetails() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [BHT, setBHT] = useState("");
  const [total, setTotal] = useState(0.0);
  const [items, setItems] = useState([]);
  const [errors, setErrors] = useState<ValidationErrors>({});

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);


  interface ValidationErrors {
    BHT?: string;
    firstName?: string;
    lastName?: string;
    address?: string;
    email?: string;
    mobile?: string;
    total?: string;
  }

  const validateForm = () => {
    let validationErrors: ValidationErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]{10}$/;

    if (!BHT.trim()) validationErrors.BHT = "BHT is required.";
    if (!firstName.trim()) validationErrors.firstName = "First Name is required.";
    if (!lastName.trim()) validationErrors.lastName = "Last Name is required.";
    if (!address.trim()) validationErrors.address = "Address is required.";
    if (!email.trim() || !emailRegex.test(email)) validationErrors.email = "Valid email is required.";
    if (!mobile.trim() || !mobileRegex.test(mobile)) validationErrors.mobile = "Valid 10-digit mobile number is required.";
    if (!total || isNaN(total) || total <= 0) validationErrors.total = "Valid amount is required.";

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  // useEffect(() => {
  //   loadItem();
  // }, []);

  // const loadItem = () => {
  //   console.log(sessionStorage.getItem("sessionId"));
  //   try {
  //     const method = Methods.POST;
  //     const URL = ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.getCart;
  //     fetch(URL, {
  //       method,
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Accept: "*/*",
  //       },
  //       body: JSON.stringify({
  //         session_id: sessionStorage.getItem("sessionId"),
  //       }),
  //     })
  //       .then((response) => response.json())
  //       .then((object) => {
  //         if (object.error == false) {
  //           setItems(object.data.data);
  //           setTotal(object.data.sub_total);
  //           //console.log(object.data);
  //         } else {
  //           console.log(object);
  //         }
  //       })
  //       .catch((error) => console.log(error));
  //   } catch (error) {
  //   } finally {
  //   }
  // };

  const proceedPayment = () => {

    if (!validateForm()) return;

    const randomNumber ="BHT" + Math.floor(Math.random() * 1000000).toString().padStart(6, '0');
    try {
      // window.location.href = `https://test.nawalokacare.com?order_id=${randomNumber}&phone=${mobile}&email=${email}&fname=${firstName}&lname=${lastName}&address=${address}&amount=${total}&bht=${BHT}`;

      const method = Methods.POST;
      const URL = ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.checkout_bht;
      const payment_type=  'BHT';
      fetch(URL, {
        method,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: JSON.stringify({
          session_id: sessionStorage.getItem("sessionId"),
          mobile: mobile,
          email: email,
          fname: firstName,
          lname: lastName,
          address: address,
          total: total,
          ref_no: randomNumber,
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object.error == false) {
            // window.location.reload();

            console.log(object);
            window.location.href = `https://test.nawalokacare.com?order_id=${randomNumber}&phone=${mobile}&email=${email}&fname=${firstName}&lname=${lastName}&address=${address}&amount=${total}&bht=${BHT} &payment_type=${payment_type}`;
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
    } finally {
    }
    // navigate("/healthchecks/success");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform submission logic here
    // You can send the form data to a backend API or perform any other required action
    console.log("Form submitted:", {
      //   name,
      //   nic,
      //   phoneNumber,
      //   date,
      //   email,
      //   time,
    });
  };

  const [selectedOption, setSelectedOption] = useState("");

  // Function to handle change in select option
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // Function to render different forms based on selected option
  const renderForm = () => {
    switch (selectedOption) {
      case "In-patient payment":
        return (
          <Box mt="20px">
            <FormControl mb="10px">
              <FormLabel>Patient BHT</FormLabel>
              <Input
                type="text"
                value={searchParams.get("merchantCustomerPhone")}
                placeholder="Enter Patient BHT"
                onChange={(e) => setMobile(e.target.value)}
                //   onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </FormControl>
          </Box>
        );
      case "Other payment":
        return <Box mt="20px">Render other payment form here</Box>;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Header />
      <Box
        backgroundImage={require("../../img/WebsiteStrip1.png")}
        backgroundSize="cover"
        backgroundPosition="center"
        height="120px"
        display="flex"
        mt="10px"
        alignItems="center"
        // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Online Bill Payment
        </Text>
      </Box>

      <Box p={[2, 4]} bg="gray.100" borderRadius="md" shadow="md">
        <Grid
          gap={[2, 4]}
          templateColumns={["1fr", "1fr", "1fr"]}
          pl={[0, "300px"]}
          pr={[0, "300px"]}
        >
          <Card>
            {/* <Heading
              mb="10px"
              pl="20px"
              pt="10px"
              fontSize="24px"
              color="#F8D107"
            >
              Billing Details
            </Heading> */}
            <Box mx="auto" width="100%" pr={[2, 6]} pl={[2, 6]}>
              <FormControl mb={[2, 4]} mt={[2, 4]}>
                <FormLabel>Payment Type</FormLabel>
                <Select
                  // placeholder='Select payment type'
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  <option value="In-patient payment">In-patient payment</option>
                  {/* <option value="Other payment">Other payment</option> */}
                </Select>
                {/* Render different forms based on selected option */}
                {renderForm()}
              </FormControl>

              {/* <FormControl mb="10px" mt="10px">
                <FormLabel>Payment Type</FormLabel>
                <Select placeholder='Select payment type'>
                  <option>In-patient payment</option>
                  <option>Other payment</option>
                </Select>
              </FormControl> */}

        <Box
              bg="gray.100" // Background color
              borderRadius="md" // Rounded corners
              p={4} // Padding inside the box
            >
              <Text fontSize="sm">
                This payment portal is exclusively for Inward Patients' Bill Payments only.
              </Text>
            </Box>

              <Text fontSize="22px" color="#F8D107">
                Patient Details
              </Text>

              <FormControl mb="10px" isRequired>
                <FormLabel>Patient BHT</FormLabel>
                <Input
                  type="text"
                  value={searchParams.get("merchantCustomerPhone")}
                  placeholder="Enter Patient BHT"
                  onChange={(e) => setBHT(e.target.value)}
                  //   onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
                {errors.BHT && <Text color="red.500">{errors.BHT}</Text>}
              </FormControl>

              <FormControl mb="10px" isRequired>
                <FormLabel>Patient First Name</FormLabel>
                <Input
                  type="text"
                  value={searchParams.get("merchantCustomerPhone")}
                  placeholder="Enter Patient First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                  //   onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
                {errors.firstName && <Text color="red.500">{errors.firstName}</Text>}
              </FormControl>

              <FormControl mb="10px" isRequired>
                <FormLabel>Patient Last Name</FormLabel>
                <Input
                  type="text"
                  value={searchParams.get("merchantCustomerPhone")}
                  placeholder="Enter Patient Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                  //   onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
                {errors.lastName && <Text color="red.500">{errors.lastName}</Text>}
              </FormControl>

              <FormControl mb="10px" isRequired>
                <FormLabel>Address</FormLabel>
                <Textarea
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder="Enter Address"
                />
                {errors.address && <Text color="red.500">{errors.address}</Text>}
              </FormControl>

              <FormControl mb="10px" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="example@example.com"
                  onChange={(e) => setEmail(e.target.value)}
                  value={searchParams.get("merchantCustomerEmail")}
                  //   onChange={(e) => setEmail(e.target.value)}
                  required
                />
                 {errors.email && <Text color="red.500">{errors.email}</Text>}
              </FormControl>

              <FormControl mb="10px" isRequired>
                <FormLabel>Mobile Number</FormLabel>
                <Input
                  type="tel"
                  value={searchParams.get("merchantCustomerPhone")}
                  placeholder="Enter Mobile Number"
                  onChange={(e) => setMobile(e.target.value)}
                  //   onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
                {errors.mobile && <Text color="red.500">{errors.mobile}</Text>}
              </FormControl>

              <FormControl mb="10px" isRequired>
                <FormLabel>
                  Amount <small>(Rs.)</small>
                </FormLabel>
                <Input
                  type="text"
                  value={searchParams.get("merchantCustomerPhone")}
                  placeholder="Enter Amount"
                  onChange={(e) => setTotal(parseFloat(e.target.value))}
                  //   onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
                {errors.total && <Text color="red.500">{errors.total}</Text>}
              </FormControl>

              <FormControl mb="10px" mt="20px">
                <Button
                  colorScheme="teal"
                  type="button"
                  onClick={() => proceedPayment()}
                >
                  Pay Now
                </Button>
              </FormControl>
            </Box>
          </Card>
        </Grid>
      </Box>
      <FooterItem />
    </Box>
  );
}

export default OnlinePaymentDetails;

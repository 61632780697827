import React, { ReactNode } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Spacer,
  Text,
  useBreakpointValue,
  Image,
  Avatar,
  Stack,
  HStack,
  VStack,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  MenuGroup,
  Grid,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import {
  FaEnvelope,
  FaWhatsapp,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaPhoneAlt,
  FaCartPlus,
} from "react-icons/fa";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
// import VirtualIcon from "../../public/img/";
// import EmergencyIcon from "/public/img/emergencytemp.png";
// import DoorstepIcon from "/public/img/doorsteptemp.png";
// import ChannelingIcon from "/public/img/doctorchannelingtemp.png";
import { useState, useEffect } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { keyframes } from "@emotion/react";
import { ECHANNELLING, ApiEndpoints } from "../constants/ApiCunstants";
import { Methods } from "../constants";

const Header = () => {
  const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

  const isMobile = useBreakpointValue({ base: true, md: false });

  // const Links = ['About Us', 'Services/Clinics', 'Treatments','International Patients','Contact us'];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const navigate = useNavigate();
  const NavLink = ({ children }: { children: ReactNode }) => (
    <Link
      px={2}
      py={1}
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        bg: useColorModeValue("gray.200", "gray.700"),
      }}
      href={"#"}
    >
      {children}
    </Link>
  );

  const [scrolled, setScrolled] = useState(false);

  // const buttonStyle = {
  //   backgroundColor: '#ff3366',
  //   color: '#fff',
  //   border: 'none',
  //   borderRadius: '4px',
  //   padding: '10px 20px',
  //   fontSize: '16px',
  //   fontWeight: 'bold',
  //   boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  // };

  // const buttonHoverStyle = {
  //   backgroundColor: '#ff2255',
  // };

  // const quickAccess = (pageSp) => {
  //   setIsLoading(true);

  //   if (pageSp == 'emg') {
  //     navigate("/emergency");
  //   } else if (pageSp == 'vir') {
  //   navigate("/virtual");
  //   } else if (pageSp == 'channel') {
  //     navigate("/channeling");
  //   } else if (pageSp == 'doorstep') {
  //       navigate("/doorstep");
  //   }
  // };

  const quickAccessOnline = (pageSp) => {
    setIsLoading(true);

    if (pageSp == "channeling") {
      navigate("/channeling");
    } else if (pageSp == "meddelivery") {
      navigate("/meddelivery");
      // window.location.href = "/meddelivery";
    } else if (pageSp == "physio") {
      navigate("/clinics/physiotherapy");
    } else if (pageSp == "labmob") {
      navigate("/labmob");
      // window.location.href = "https://labreport.lk/upload-prescription";
    } else if (pageSp == "adresv") {
      navigate("/admresev");
    }
  };

  const careerMenu = () => {
    setIsLoading(true);
    navigate("/careers/searchjobs");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    getCartCount();
    AOS.init({
      duration: 1000,
      easing: "ease-out",
      once: true,
    });
  }, []);

  const getCartCount = () => {
    console.log(sessionStorage.getItem("sessionId"));
    try {
      const method = Methods.POST;
      const URL =
        ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.cart_count;
      fetch(URL, {
        method,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: JSON.stringify({
          session_id: sessionStorage.getItem("sessionId"),
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object.error == false) {
            setCartCount(object.count);
            //console.log(object.data);
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
    } finally {
    }
  };

  const handleOpenPDF = () => {
    // Replace 'path/to/your/pdf.pdf' with the actual path to your PDF file
    const pdfPath = '../FOR-IMMEDIATE-RELEASE.pdf';

    // Open the PDF in a new window
    window.open(pdfPath, '_blank');
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // useEffect(() => {
  //   // Check if the current location is the home page
  //   if (location.pathname === '/') {
  //     // Open the modal when the component mounts
  //     handleOpenModal();
  //   }
  // }, [location.pathname]);



  return (
    <Box>
      <Flex margin="0 auto" flexDirection="row" display="block">
        <Flex bg="#00afef" px={8} py={2} alignItems="center" h={9}>
          {!isMobile && (
            <>
              <Box display="flex" alignItems="center" ml={-4}>
                <Box mr={1}>
                  <Icon
                    as={FaPhoneAlt}
                    boxSize={5}
                    color="whiteAlpha.900"
                    mt="5px"
                  />
                </Box>
                <Text color="whiteAlpha.900" fontWeight={"bold"}>
                  0115 577 111
                </Text>
              </Box>
              <Box display="flex" alignItems="center" mx={5} ml="3">
                <Box mr={1}>
                  <Icon
                    as={FaEnvelope}
                    boxSize={5}
                    color="whiteAlpha.900"
                    mt="5px"
                  />
                </Box>
                <Text color="whiteAlpha.900" fontWeight={"bold"}>
                  nawaloka@slt.lk
                </Text>
              </Box>
              {/* <Box display="flex" alignItems="center" mx={5} ml="0">
                <Box mr={1}>
                  <Icon
                    as={FaWhatsapp}
                    boxSize={5}
                    color="whiteAlpha.900"
                    mt="5px"
                  />
                </Box>
                <Text color="whiteAlpha.900" fontWeight={"bold"}>
                  0115 777 777
                </Text>
              </Box> */}

              <Spacer />
            </>
          )}
          <Box display="flex" alignItems="center" mx={5} ml="2">
            <Text color="whiteAlpha.900" fontWeight={"bold"}>
              LIFE LINE - 1514
            </Text>
          </Box>
          {isMobile && (
            <>
              <Spacer />
            </>
          )}
          <Box display="flex" alignItems="center">
            {/* ---------------------------Cart Icon--------------------------------- */}

            <Box position="relative">
              {cartCount !== 0 && (
                <>
                  <IconButton
                    aria-label="Facebook"
                    icon={<FaCartPlus />}
                    size="md"
                    variant="ghost"
                    color="whiteAlpha.900"
                    _hover={{
                      opacity: 0.6,
                    }}
                    onClick={() => navigate("/healthchecks/cart")}
                  />
                  <Badge
                    colorScheme="red"
                    borderRadius="full"
                    fontSize="10px"
                    position="absolute"
                    top="2"
                    right="0"
                  >
                    {cartCount}
                  </Badge>
                </>
              )}
            </Box>

            {/* --------------------------- End of Cart Icon--------------------------------- */}

            <IconButton
              aria-label="Facebook"
              icon={<FaFacebook />}
              size="md"
              variant="ghost"
              color="whiteAlpha.900"
              _hover={{
                opacity: 0.6,
              }}
              // mr={1}
              onClick={() =>
                window.open(
                  "https://web.facebook.com/NawalokaHospitalColombo?_rdc=1&_rdr",
                  "_blank"
                )
              }
            />

            <IconButton
              aria-label="Twitter"
              icon={<FaTwitter />}
              size="md"
              variant="ghost"
              color="whiteAlpha.900"
              _hover={{
                opacity: 0.6,
              }}
              // mr={1}
              onClick={() =>
                window.open("https://twitter.com/Nawalokahospitl", "_blank")
              }
            />
            <IconButton
              aria-label="Instagram"
              icon={<FaInstagram />}
              size="md"
              variant="ghost"
              color="whiteAlpha.900"
              _hover={{
                opacity: 0.6,
              }}
              onClick={() =>
                window.open(
                  "https://www.instagram.com/nawalokahospitalcolombo/",
                  "_blank"
                )
              }
            />
          </Box>
        </Flex>
      </Flex>
      <Flex
        justifyContent={{ base: "center", md: "left" }}
        alignItems="center"
        mt="-20px"
        height="100px"
      >
        <Link as={RouterLink} to="/" _hover={{ textDecoration: "none" }}>
          <Box
            ml={{ base: "10px", lg: "450px" }}
            justifyContent={{ base: "center", md: "left" }}
            flex="1"
            display="flex"
            alignItems="center"
          >
            <Image
              src={require("../img/NawalokaPLC.png")}
              alt="Logo"
              maxH="50px"
              mr="10px"
              mt="15px"
            />
            <Text
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight="bold"
              color="#00A550"
              lineHeight={{ base: "1.2em", md: "normal" }}
              textAlign={{ base: "center", md: "left", lg: "center" }}
            >
              Nawaloka Hospitals PLC
            </Text>
          </Box>
        </Link>

        <Spacer display={isMobile ? "none" : "block"} />

        <Box display={isMobile ? "none" : "block"}>
          <Box
            alignItems="flex-start"
            mt="10px"
            mb="10px"
            justifyContent="flex-end"
          >

            <Box display="flex" alignItems="center" mx={5} ml="0">
              <Box mr={1}>
                <Image
                  // as={FaWhatsapp}
                  src={require("../img/icons/whatsapplogo.png")}
                  boxSize={8}
                  color="black"
                  mt="5px"
                />
              </Box>
              <Text color="green" fontSize={'xl'} fontWeight={"bold"}>
                0115 777 777
              </Text>
            </Box>

            {/* <Link as={RouterLink} to="/access">
              <Button
                colorScheme="gray"
                size={{ base: "xs", md: "xs", lg: "sm" }}
                fontSize={{ base: "xxs", md: "xxs", lg: "xs" }}
                px={8}
                border="1px"
                mb="2px"
                bgColor="#1A908F"
                borderColor="whiteAlpha.300"
                color="white"
                borderRadius={20}
                mr={2}
                _hover={{
                  background: "rgba(255, 255, 255, 0.2)",
                  color: "#1A908F",
                  borderColor: "#1A908F",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                Book Online
              </Button>
            </Link> */}


            {/* <Link as={RouterLink} to="/treatments/surgery">
              <Button colorScheme="gray" 
              size={{base: "xs", md: "xs", lg: "sm"}} 
              fontSize={{base: "xxs", md: "xxs", lg: "xs"}} 
              px={8} 
              border="1px" 
              mb="2px" 
              bgColor="#1A908F" 
              borderColor="whiteAlpha.300"
              color="white"
              borderRadius={20} 
              mr={2}
              _hover={{
                background: 'rgba(255, 255, 255, 0.2)',
                color: '#1A908F',
                borderColor: '#1A908F',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              >
                Call an agent
              </Button>
            </Link> */}
          </Box>
        </Box>

        {/* <Flex>
          <Link as={RouterLink} to="/international">
            <Button colorScheme="gray" size={'xs'} fontSize="xs" px={8} borderRadius={20}>
              More
            </Button>
          </Link>
          </Flex> */}
      </Flex>

      <Box
        bg="rgba(255, 255, 255, 0.7)"
        px={4}
        h="53px"
        mt="2"
        width="100%"
        position={scrolled ? "fixed" : "relative"}
        top={scrolled ? "-10px" : "-25px"}
        left={0}
        right={0}
        zIndex={999}
      >
        <Flex h={12} alignItems={"center"} justifyContent={"space-between"}>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              size={{ base: "sm", md: "sm", lg: "md" }}
              fontSize={{ base: "sm", md: "lg", lg: "lg" }}
              px={8}
              border="2px"
              mb="2px"
              bgColor="#D22B2B"
              borderColor="whiteAlpha.300"
              color="white"
              borderRadius={20}
              mr={2}
              mt={2}
              animation={`${blinkAnimation} 2s linear infinite`}
              _hover={{
                background: "rgba(255, 255, 255, 0.2)",
                color: "#D22B2B",
                borderColor: "#D22B2B",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
              _active={{
                background: "rgba(255, 255, 255, 0.2)",
                color: "#D22B2B",
                borderColor: "#D22B2B",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              ONLINE BOOKINGS
              {/* Don't stand on Lines */}
            </MenuButton>
            {/* <MenuList>
        <MenuItem minH='48px' onClick={() => quickAccess('emg')}>
          <Image
            boxSize='3rem'
            borderRadius='full'
            src={require('../img/icons/emergencytemp.png')}
            alt='Fluffybuns the destroyer'
            mr='12px'
          />
          <span>Emergency Care</span>
        </MenuItem>
        <MenuItem minH='40px' onClick={() => quickAccess('vir')}>
          <Image
            boxSize='3rem'
            borderRadius='full'
            src={require('../img/icons/virtualassistancetemp.png')}
            alt='Simon the pensive'
            mr='12px'
          />
          <span>Virtual Assistance</span>
        </MenuItem>
        <MenuItem minH='40px'  onClick={() => quickAccess('channel')}>
          <Image
            boxSize='3rem'
            borderRadius='full'
            src={require('../img/icons/doctorchannelingtemp.png')}
            alt='Simon the pensive'
            mr='12px'
          />
          <span>Doctor Channeling</span>
        </MenuItem>
        <MenuItem minH='40px'  onClick={() => quickAccess('doorstep')}>
          <Image
            boxSize='3rem'
            borderRadius='full'
            src={require('../img/icons/doorsteptemp.png')}
            alt='Simon the pensive'
            mr='12px'
          />
          <span>Doorstep Services</span>
        </MenuItem>
      </MenuList> */}

            {/* Book Online Menu List  */}

            <MenuList>
              <MenuItem
                minH="48px"
                onClick={() => quickAccessOnline("channeling")}
              >
                <Image
                  boxSize="3rem"
                  borderRadius="full"
                  src={require("../img/icons/emergencytemp.png")}
                  alt="Fluffybuns the destroyer"
                  mr="12px"
                />
                <VStack lineHeight="10px" alignItems="flex-start">
                  <span>Doctor Channeling</span>
                  <small>Find your Doctor</small>
                </VStack>
              </MenuItem>
              {/* <MenuItem
                minH="40px"
                onClick={() => quickAccessOnline("meddelivery")}
              >
                <Image
                  boxSize="3rem"
                  borderRadius="full"
                  src={require("../img/icons/virtualassistancetemp.png")}
                  alt="Simon the pensive"
                  mr="12px"
                />
                <VStack lineHeight="10px" alignItems="flex-start">
                  <span>Medicine Delivery</span>
                  <small>Upload your Perscription</small>
                </VStack>
              </MenuItem> */}
              <MenuItem minH="40px" onClick={() => quickAccessOnline("physio")}>
                <Image
                  boxSize="3rem"
                  borderRadius="full"
                  src={require("../img/icons/doctorchannelingtemp.png")}
                  alt="Simon the pensive"
                  mr="12px"
                />
                <VStack lineHeight="10px" alignItems="flex-start">
                  <span>Physiotherapy</span>
                  <small>Book a Session </small>
                </VStack>
              </MenuItem>
              <MenuItem minH="40px" onClick={() => quickAccessOnline("labmob")}>
                <Image
                  boxSize="3rem"
                  borderRadius="full"
                  src={require("../img/icons/doorsteptemp.png")}
                  alt="Simon the pensive"
                  mr="12px"
                />
                <VStack lineHeight="10px" alignItems="flex-start">
                  <span>Lab Mobile</span>
                  <small>Upload your Reports</small>
                </VStack>
              </MenuItem>
              <MenuItem minH="40px" onClick={() => quickAccessOnline("adresv")}>
                <Image
                  boxSize="3rem"
                  borderRadius="full"
                  src={require("../img/icons/admission-icon.png")}
                  alt="Simon the pensive"
                  mr="12px"
                />
                <VStack lineHeight="10px" alignItems="flex-start">
                  <span>Admission Reservation</span>
                  <small>Reserve a Admission</small>
                </VStack>
              </MenuItem>
            </MenuList>
          </Menu>

          {/* -------Horizontal Icon set start------- */}
          {/* <Flex alignItems={'right'}>
      <HStack spacing={3} marginTop="20px">
        <VStack spacing={4}>
          <Link as={RouterLink} to='/emergency' style={{ display: "block", textAlign: "center", textDecoration: "none" }}>
            <IconButton icon={<Image src={require('../img/icons/emergencytemp.png')} alt="Custom Icon" width={12} height={12} />}
              aria-label="Custom Icon" variant="unstyled" />
            <Text fontSize="sm" mt="2" fontWeight="bold" color="#126A69" lineHeight="1.2em" textAlign="center">Emergency<br/>Care</Text>
          </Link>
        </VStack>
        <VStack spacing={2}>
          <Link as={RouterLink} to='/virtual' style={{ display: "block", textAlign: "center", textDecoration: 'none' }}>
            <IconButton icon={<Image src={require('../img/icons/virtualassistancetemp.png')} alt="Custom Icon" width={12} height={12} />}
              aria-label="Custom Icon" variant="unstyled" />
            <Text fontSize="sm" mt="2" fontWeight="bold" color="#126A69" lineHeight="1.2em" textAlign="center">Virtual<br/>Assistance</Text>
          </Link>
        </VStack>
        <VStack spacing={2}>
          <Link as={RouterLink} to='/channeling' style={{ display: "block", textAlign: "center", textDecoration: 'none' }}>
            <IconButton icon={<Image src={require('../img/icons/doctorchannelingtemp.png')} alt="Custom Icon" width={12} height={12} />}
              aria-label="Custom Icon" variant="unstyled" />
            <Text fontSize="sm" mt="2" fontWeight="bold" color="#126A69" lineHeight="1.2em" textAlign="center">Doctor<br/>Channeling</Text>
          </Link>
        </VStack>
        <VStack spacing={2}>
          <Link as={RouterLink} 
          to='/doorstep' 
          style={{ display: "block", textAlign: "center", textDecoration: 'none' }}>
            <IconButton icon={<Image src={require('../img/icons/doorsteptemp.png')} 
            alt="Custom Icon" 
            width={12} 
            height={12} 
            // _hover={{ width: '55', height:'55'}}
            />}
              aria-label="Custom Icon" variant="unstyled" />
            <Text fontSize="sm" mt="2" fontWeight="bold" color="#126A69" lineHeight="1.2em" textAlign="center">Doorstep<br/>Services</Text>
          </Link>
        </VStack>

      </HStack>
    </Flex> */}
          {/* -------Horizontal Icon set end------- */}

          <HStack spacing={8} alignItems={"center"}>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
            >

              {/* <Link as={RouterLink} to='/aboutus' key='About us' style={{ textDecoration: 'none' }}>About Us</Link> */}
              {/* <Link as={RouterLink} to='/' key='Services/Clinics' style={{ textDecoration: 'none' }}>Services/Clinics</Link> */}


              <Menu>
                <MenuButton
                  as={Button}
                  color="black"
                  fontSize="md"
                  rightIcon={<Icon as={ChevronDownIcon} boxSize={4} />}
                  variant="ghost"
                  _hover={{ fontSize: "lg" }}
                  _expanded={{ bg: "transparent" }}
                  _focus={{ outline: "none" }}
                  _active={{ fontSize: "lg" }}
                  px={0} // Remove horizontal padding
                  py={0} // Remove vertical padding
                >
                  About Us
                </MenuButton>
                <MenuList minWidth="150px">
                  <Stack direction="column" spacing={4}>
                    <MenuItem
                      as={RouterLink}
                      to="/aboutus"
                      fontWeight={"semibold"}
                      textAlign="justify"
                      pl={4}
                      pr={4}
                      _focus={{ bg: "none" }}
                      _hover={{ bg: "gray.100" }}
                      _expanded={{ bg: "transparent" }}
                      _active={{ bg: "gray.100" }}
                    >
                      Our Story
                    </MenuItem>
                  </Stack>
                  <Stack direction="column" spacing={4}>
                    <MenuItem
                      as={RouterLink}
                      to="/aboutus/message"
                      fontWeight={"semibold"}
                      textAlign="justify"
                      pl={4}
                      pr={4}
                      _focus={{ bg: "none" }}
                      _hover={{ bg: "gray.100" }}
                      _expanded={{ bg: "transparent" }}
                      _active={{ bg: "gray.100" }}
                    >
                      Chairman's Message
                    </MenuItem>
                  </Stack>
                  <Stack direction="column" spacing={4}>
                    <MenuItem
                      as={RouterLink}
                      to="/aboutus/awards"
                      fontWeight={"semibold"}
                      textAlign="justify"
                      pl={4}
                      pr={4}
                      _focus={{ bg: "none" }}
                      _hover={{ bg: "gray.100" }}
                      _expanded={{ bg: "transparent" }}
                      _active={{ bg: "gray.100" }}
                    >
                      Awards & Recognitions
                    </MenuItem>
                  </Stack>
                  <Stack direction="column" spacing={4}>
                    <MenuItem
                      as={RouterLink}
                      to="/aboutus/reports"
                      fontWeight={"semibold"}
                      textAlign="justify"
                      pl={4}
                      pr={4}
                      _focus={{ bg: "none" }}
                      _hover={{ bg: "gray.100" }}
                      _expanded={{ bg: "transparent" }}
                      _active={{ bg: "gray.100" }}
                    >
                      Annual Reports
                    </MenuItem>
                  </Stack>
                  <Stack direction="column" spacing={0}>
                    <Menu placement="right-start">
                      <MenuButton
                        as={Button}
                        color="balck"
                        fontSize="md"
                        rightIcon={<Icon as={ChevronRightIcon} boxSize={4} />}
                        variant="ghost"
                        _hover={{ bg: "gray.100" }}
                        _expanded={{ bg: "transparent" }}
                        _active={{ bg: "gray.100" }}
                        textAlign="justify"
                      >
                        Cutting Edge Technology at Our Facilities
                      </MenuButton>
                      <MenuList>
                        <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                          <MenuItem as={RouterLink} to="/aboutus/technology">
                            Our Technology
                          </MenuItem>
                          <MenuItem as={RouterLink} to="/aboutus/facilities">
                            Our Facilities
                          </MenuItem>
                        </Grid>
                      </MenuList>
                    </Menu>
                  </Stack>
                  <Stack direction="column" spacing={4}>
                    <MenuItem
                      as={RouterLink}
                      to="/aboutus/consultants"
                      fontWeight={"semibold"}
                      textAlign="justify"
                      pl={4}
                      pr={4}
                      _focus={{ bg: "none" }}
                      _hover={{ bg: "gray.100" }}
                      _expanded={{ bg: "transparent" }}
                      _active={{ bg: "gray.100" }}
                    >
                      Resident Consultants
                    </MenuItem>
                  </Stack>
                </MenuList>

              </Menu>




              <Menu>
                <MenuButton
                  as={Button}
                  color="black"
                  fontSize="md"
                  rightIcon={<Icon as={ChevronDownIcon} boxSize={4} />}
                  variant="ghost"
                  _hover={{ fontSize: "lg" }}
                  _expanded={{ bg: "transparent" }}
                  _focus={{ outline: "none" }}
                  _active={{ fontSize: "lg" }}
                  px={0} // Remove horizontal padding
                  py={0} // Remove vertical padding
                >
                  Centre of Excellence
                </MenuButton>

                <MenuList>
                  <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                    <MenuItem
                      as={RouterLink}
                      to="/heart-centre"
                    >
                      Heart Centre
                    </MenuItem>
                    <MenuItem
                      as={RouterLink}
                      to="/servicepages/neurocare"
                    >
                      Neuro Centre
                    </MenuItem>
                    <MenuItem as={RouterLink} to="/servicepages/rns">
                      Rheumatology & Sports Medicine
                    </MenuItem>
                    <MenuItem as={RouterLink} to="/servicepage">
                      Ear Nose & Throat Care
                    </MenuItem>
                    <MenuItem
                      as={RouterLink}
                      to="/servicepages/fertilitycare"
                    >
                      Fertility Care
                    </MenuItem>
                    <MenuItem
                      as={RouterLink}
                      to="/radiology"
                    >
                      Radiology & Imaging
                    </MenuItem>
                    <MenuItem
                      as={RouterLink}
                      to="/servicepages/momandbaby"
                    >
                      Mother & Baby
                    </MenuItem>
                    <MenuItem
                            as={RouterLink}
                            to="/servicepages/cosmetic"
                          >
                            Skin Care & Cosmetic Centre
                          </MenuItem>
                          <MenuItem
                            as={RouterLink}
                            to="/servicepages/dental"
                          >
                            Dental Unit
                          </MenuItem>
                          <MenuItem
                            as={RouterLink}
                            to="/servicepages/diabetes"
                          >
                            Diabetes Centre
                          </MenuItem>
                    <MenuItem
                      as={RouterLink}
                      to="/servicepages/cancer"
                    >
                      Cancer and Blood Disease
                    </MenuItem>
                    <MenuItem
                      as={RouterLink}
                      to="/elitecentre"
                    >
                      Elite Centre
                    </MenuItem>

                    {/* <MenuItem as={RouterLink} to="/servicepage">Cancer Care</MenuItem>
                              <MenuItem as={RouterLink} to="/servicepage">Mother & Baby Care</MenuItem>
                              <MenuItem as={RouterLink} to="/servicepage">Skin & Cosmetic Care</MenuItem>
                              <MenuItem as={RouterLink} to="/servicepage">Bone Marrow</MenuItem>
                              <MenuItem as={RouterLink} to="/servicepage">Pharmacy</MenuItem>
                              <MenuItem as={RouterLink} to="/servicepage">Digestive Care/Endoscopy</MenuItem>
                              <MenuItem as={RouterLink} to="/servicepage">Accident, Emergency & OPD</MenuItem>
                              <MenuItem as={RouterLink} to="/servicepage">Fertility Centre</MenuItem>
                              <MenuItem as={RouterLink} to="/servicepage">Radiology</MenuItem>
                              <MenuItem as={RouterLink} to="/servicepage">Laboratory & Lab Network</MenuItem>  */}

                  </Grid>
                </MenuList>
              </Menu>

              <Menu>
                <MenuButton
                  as={Button}
                  color="black"
                  fontSize="md"
                  rightIcon={<Icon as={ChevronDownIcon} boxSize={4} />}
                  variant="ghost"
                  _hover={{ fontSize: "lg" }}
                  _expanded={{ bg: "transparent" }}
                  _focus={{ outline: "none" }}
                  _active={{ fontSize: "lg" }}
                  px={0} // Remove horizontal padding
                  py={0} // Remove vertical padding
                >
                  Services/Clinics
                </MenuButton>
                <MenuList minWidth="150px">
                  <Stack direction="column" spacing={4}>
                    <MenuItem
                      as={RouterLink}
                      to="/treatments/surgery"
                      fontWeight={"semibold"}
                      textAlign="justify"
                      pl={4}
                      pr={4}
                      _focus={{ bg: "none" }}
                      _hover={{ bg: "gray.100" }}
                      _expanded={{ bg: "transparent" }}
                      _active={{ bg: "gray.100" }}
                    >
                      Surgery Infomation
                    </MenuItem>
                  </Stack>
                  <Stack direction="column" spacing={0}>
                    <Menu placement="right-start">
                      <MenuButton
                        as={Button}
                        color="balck"
                        fontSize="md"
                        rightIcon={<Icon as={ChevronRightIcon} boxSize={4} />}
                        variant="ghost"
                        _hover={{ bg: "gray.100" }}
                        _expanded={{ bg: "transparent" }}
                        _active={{ bg: "gray.100" }}
                        textAlign="justify"
                      >
                        Clinics
                      </MenuButton>
                      <MenuList>
                        <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                          <MenuItem as={RouterLink} to="/clinics/physiotherapy">
                            Physiotherapy
                          </MenuItem>
                          <MenuItem as={RouterLink} to="/clinics/eyecare">
                            Eye Care
                          </MenuItem>
                          <MenuItem as={RouterLink} to="/clinics/bloodbank">Blood Bank</MenuItem>
                          <MenuItem as={RouterLink} to="/clinics/nutrition">Nutrition & Dietetics</MenuItem>
                          {/* <MenuItem as={RouterLink} to="/elitecentre">Elite Centre</MenuItem> */}

                          {/* <MenuItem as={RouterLink} to="/service1/subservice2">Diabetic</MenuItem>
                          <MenuItem as={RouterLink} to="/service2/subservice2">Geriatric</MenuItem>
                          <MenuItem as={RouterLink} to="/service2/subservice1">Respiratory</MenuItem>
                          <MenuItem as={RouterLink} to="/service2/subservice2">Immunization</MenuItem>
                          <MenuItem as={RouterLink} to="/service2/subservice2">EMG</MenuItem>
                          <MenuItem as={RouterLink} to="/service2/subservice2">Drug Rehabilitation</MenuItem>
                          <MenuItem as={RouterLink} to="/service2/subservice2">Dental</MenuItem>
                          <MenuItem as={RouterLink} to="/service2/subservice2">Trauma Management</MenuItem>
                          <MenuItem as={RouterLink} to="/service2/subservice2">Breast Care</MenuItem>
                          <MenuItem as={RouterLink} to="/service2/subservice2">Headache</MenuItem>
                          <MenuItem as={RouterLink} to="/service2/subservice2">Speech Therapy</MenuItem>
                          <MenuItem as={RouterLink} to="/service2/subservice2">Health Check</MenuItem>
                          <MenuItem as={RouterLink} to="/service2/subservice2">Memory Clinic</MenuItem>
                          <MenuItem as={RouterLink} to="/service2/subservice2">Stress Management</MenuItem>
                          <MenuItem as={RouterLink} to="/service2/subservice2">Menopause</MenuItem>
                          <MenuItem as={RouterLink} to="/service2/subservice2">Stroke</MenuItem> */}
                        </Grid>
                      </MenuList>
                    </Menu>
                  </Stack>

                  <Stack direction="column" spacing={4}>
                    <MenuItem
                      as={RouterLink}
                      to="/laboratory"
                      fontWeight={"semibold"}
                      textAlign="justify"
                      pl={4}
                      pr={4}
                      _focus={{ bg: "none" }}
                      _hover={{ bg: "gray.100" }}
                      _expanded={{ bg: "transparent" }}
                      _active={{ bg: "gray.100" }}
                    >
                      Laboratory
                    </MenuItem>
                  </Stack>

                  <Stack direction="column" spacing={4}>
                    <MenuItem
                      as={RouterLink}
                      to="/healthchecks"
                      fontWeight={"semibold"}
                      textAlign="justify"
                      pl={4}
                      pr={4}
                      _focus={{ bg: "none" }}
                      _hover={{ bg: "gray.100" }}
                      _expanded={{ bg: "transparent" }}
                      _active={{ bg: "gray.100" }}
                    >
                      Health Checks
                    </MenuItem>
                  </Stack>

                  <Stack>
                    <MenuItem
                      as={RouterLink}
                      to="/payments"
                      fontWeight={"semibold"}
                      textAlign="justify"
                      pl={4}
                      pr={4}
                      _focus={{ bg: "none" }}
                      _hover={{ bg: "gray.100" }}
                      _expanded={{ bg: "transparent" }}
                      _active={{ bg: "gray.100" }}
                    >
                      Online Payments
                    </MenuItem>
                  </Stack>

                  {/* <Stack>
                    <MenuItem
                      as={RouterLink}
                      to="/voucher"
                      fontWeight={"semibold"}
                      textAlign="justify"
                      pl={4}
                      pr={4}
                      _focus={{ bg: "none" }}
                      _hover={{ bg: "gray.100" }}
                      _expanded={{ bg: "transparent" }}
                      _active={{ bg: "gray.100" }}
                    >
                      Mother's day voucher
                    </MenuItem>
                  </Stack> */}

                  {/* <Stack>
                    <MenuItem
                      as={RouterLink}
                      to="/emailtemp"
                      fontWeight={"semibold"}
                      textAlign="justify"
                      pl={4}
                      pr={4}
                      _focus={{ bg: "none" }}
                      _hover={{ bg: "gray.100" }}
                      _expanded={{ bg: "transparent" }}
                      _active={{ bg: "gray.100" }}
                    >
                      Email
                    </MenuItem>
                  </Stack> */}

                  {/* <Stack>
                    <MenuItem
                      as={RouterLink}
                      to="/any-payment"
                      fontWeight={"semibold"}
                      textAlign="justify"
                      pl={4}
                      pr={4}
                      _focus={{ bg: "none" }}
                      _hover={{ bg: "gray.100" }}
                      _expanded={{ bg: "transparent" }}
                      _active={{ bg: "gray.100" }}
                    >
                      Payment Portal
                    </MenuItem>
                  </Stack> */}
                </MenuList>
              </Menu>

              <Link
                as={RouterLink}
                to="/international"
                key="International Patients"
                style={{ textDecoration: "none" }}
                fontWeight={"semibold"}
                _hover={{ fontSize: "lg" }}
                _expanded={{ bg: "transparent" }}
                _active={{ fontSize: "lg" }}
              >
                International Patients
              </Link>

              <Menu>
                <MenuButton
                  as={Button}
                  color="black"
                  fontSize="md"
                  rightIcon={<Icon as={ChevronDownIcon} boxSize={4} />}
                  variant="ghost"
                  _hover={{ fontSize: "lg" }}
                  _expanded={{ bg: "transparent" }}
                  _focus={{ outline: "none" }}
                  _active={{ fontSize: "lg" }}
                  px={0} // Remove horizontal padding
                  py={0} // Remove vertical padding
                >
                  News & Media
                </MenuButton>
                <MenuList minWidth="150px">
                  
                  <Stack direction="column" spacing={4}>
                    {/* <MenuItem
                      as={RouterLink}
                      to="/"
                      fontWeight={"semibold"}
                      textAlign="justify"
                      pl={4}
                      pr={4}
                      _focus={{ bg: "none" }}
                      _hover={{ bg: "gray.100" }}
                      _expanded={{ bg: "transparent" }}
                      _active={{ bg: "gray.100" }}
                      onClick={handleOpenModal}
                    >
                      Media Releases
                    </MenuItem> */}

                    {/* Modal */}
                    <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="xl">
                      <ModalOverlay />
                      <ModalContent bg="white">
                        {/* <ModalHeader>SPECIAL NOTICE</ModalHeader> */}
                        <ModalCloseButton style={{ position: "absolute", marginLeft: "8px" }} />
                        <ModalBody>
                          {/* Add your image here */}
                          <Image src={require("../img/wellnesspic.jpeg")} alt="Special Notice" maxW="100%" maxH="100%" />

                          {/* You can also add additional content or a PDF viewer below the image */}
                          {/* Example: <iframe src="path/to/your/pdf.pdf" width="100%" height="500px" /> */}
                        </ModalBody>
                        {/* <ModalFooter>
                        <Flex alignItems="center" justifyContent="center" flexDirection="column">
                        
                          <a href="/voucher">
                          <Button backgroundColor="#D43790" color='white' _hover={{
                                    bg: '#ff748c',
                                    color: 'white',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                                  }} 
                                  fontSize="xl" px={8} borderRadius={20}>
                            Purchase Voucher
                          </Button>
                        </a>
                        </Flex>
                        </ModalFooter> */}
                      </ModalContent>
                    </Modal>
                  </Stack>

                  <Stack direction="column" spacing={4}>
                    <MenuItem
                      as={RouterLink}
                      to="/media"
                      fontWeight={"semibold"}
                      textAlign="justify"
                      pl={4}
                      pr={4}
                      _focus={{ bg: "none" }}
                      _hover={{ bg: "gray.100" }}
                      _expanded={{ bg: "transparent" }}
                      _active={{ bg: "gray.100" }}
                    >
                      Videos
                    </MenuItem>
                  </Stack>
                  <Stack direction="column" spacing={4}>
                    <MenuItem
                      as={RouterLink}
                      to="/promotions"
                      fontWeight={"semibold"}
                      textAlign="justify"
                      pl={4}
                      pr={4}
                      _focus={{ bg: "none" }}
                      _hover={{ bg: "gray.100" }}
                      _expanded={{ bg: "transparent" }}
                      _active={{ bg: "gray.100" }}
                    >
                      Ongoing Promotions
                    </MenuItem>
                  </Stack>
                  {/* <Stack direction="column" spacing={4}>
                    <MenuItem
                      as={RouterLink}
                      to="/kisspromo"
                      fontWeight={"semibold"}
                      textAlign="justify"
                      pl={4}
                      pr={4}
                      _focus={{ bg: "none" }}
                      _hover={{ bg: "gray.100" }}
                      _expanded={{ bg: "transparent" }}
                      _active={{ bg: "gray.100" }}
                    >
                      Kiss Promotion
                    </MenuItem>
                  </Stack> */}
                </MenuList>
              </Menu>




              {/* <Menu> */}
              {/* <MenuButton
                as={Button}
                color="black"
                fontSize="md"
                rightIcon={<Icon as={ChevronDownIcon} boxSize={4} />}
                variant="ghost"
                _hover={{ fontSize: 'lg' }}
                _expanded={{ bg: 'transparent' }}
                _focus={{ outline: 'none' }}
                _active={{ fontSize: 'lg' }}
                px={0} // Remove horizontal padding
                py={0} // Remove vertical padding
              >
                Careers
              </MenuButton> */}
              {/* <MenuList minWidth="150px">
                  <Stack direction="column" spacing={4}>
                    <Menu placement="right-start">
                      <MenuButton
                        as={Button}
                        color="balck"
                        fontSize="md"
                        // rightIcon={<Icon as={ChevronRightIcon} boxSize={4} />}
                        variant="ghost"
                        _hover={{ bg: "gray.100" }}
                        _expanded={{ bg: "transparent" }}
                        _active={{ bg: "gray.100" }}
                        onClick={() => careerMenu()}
                      >
                        Search Jobs
                      </MenuButton>
                    </Menu>
                    <Menu placement="right-start">
                      <MenuButton
                        as={Button}
                        color="balck"
                        fontSize="md"
                        rightIcon={<Icon as={ChevronRightIcon} boxSize={4} />}
                        variant="ghost"
                        _hover={{ bg: "gray.100" }}
                        _expanded={{ bg: "transparent" }}
                        _active={{ bg: "gray.100" }}
                      >
                        Infomation
                      </MenuButton>
                      <MenuList>
                        <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                          <MenuItem as={RouterLink} to="/clinics/physiotherapy">
                            test 1
                          </MenuItem>
                          <MenuItem as={RouterLink} to="/clinics/eyecare">
                            test 2
                          </MenuItem>
                        </Grid>
                      </MenuList>
                    </Menu>
                  </Stack>
                </MenuList>
              </Menu> */}

              <Link
                as={RouterLink}
                to="/contactus"
                key="Contact us"
                style={{ textDecoration: "none" }}
                fontWeight={"semibold"}
                _hover={{ fontSize: "lg" }}
                _expanded={{ bg: "transparent" }}
                _active={{ fontSize: "lg" }}
              >
                Contact us
              </Link>
            </HStack>
            <IconButton
              size={"md"}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={"Open Menu"}
              display={{ md: "none" }}
              onClick={isOpen ? onClose : onOpen}
            />
          </HStack>
        </Flex>

        {isOpen ? (
          <Box
            p={4}
            mt="30px"
            display={{ md: "none" }}
            backgroundColor={"whiteAlpha.900"}
            border="1px"
            borderRadius="10px"
          >
            <Stack as={"nav"} spacing={4}>
              {/* {Links.map((link) => (
          <NavLink key={link}><Text css={{ fontWeight: 'bold' }}>{link}</Text></NavLink>
        ))} */}
              {/* <Link as={RouterLink} to='/aboutus' key='About us' style={{ textDecoration: 'none' }}>About Us</Link> */}

              <Menu placement="bottom-end">
                <Flex justifyContent="flex-start">
                  <MenuButton
                    as={Button}
                    color="black"
                    fontSize="md"
                    rightIcon={<Icon as={ChevronDownIcon} boxSize={4} />}
                    variant="ghost"
                    _hover={{ bg: "transparent" }}
                    _expanded={{ bg: "transparent" }}
                    _focus={{ outline: "none" }}
                  >
                    About Us
                  </MenuButton>
                </Flex>
                <MenuList minWidth="150px" border="1px">
                  <MenuItem as={RouterLink} to="/aboutus">
                    Our Story
                  </MenuItem>
                  <MenuItem as={RouterLink} to="/aboutus/message">
                    Chairman's Message
                  </MenuItem>
                  <MenuItem as={RouterLink} to="/aboutus/awards">
                    Awards & Recognitions
                  </MenuItem>
                  <MenuItem as={RouterLink} to="/aboutus/reports">
                    Annual Reports
                  </MenuItem>

                  <Menu placement="bottom-end">
                    <Flex justifyContent="flex-start">
                      <MenuButton
                        as={Button}
                        // color="black"
                        fontSize="md"
                        fontWeight={'normal'}
                        rightIcon={<Icon as={ChevronDownIcon} boxSize={4} />}
                        variant="ghost"
                        _hover={{ bg: "transparent" }}
                        _expanded={{ bg: "transparent" }}
                        _focus={{ outline: "none" }}
                      >
                        Cutting Edge Technology at Our Facilities
                      </MenuButton>
                    </Flex>
                    <MenuList minWidth="150px" border="1px">
                      <MenuItem as={RouterLink} to="/aboutus/technology">
                        Our Technology
                      </MenuItem>
                      <MenuItem as={RouterLink} to="/aboutus/facilities">
                        Our Facilities
                      </MenuItem>
                      {/* <MenuItem as={RouterLink} to="/service2/subservice2">Procedure</MenuItem> */}
                    </MenuList>
                  </Menu>

                  <MenuItem as={RouterLink} to="/aboutus/consultants">
                    Resident Consultants
                  </MenuItem>
                </MenuList>
              </Menu>

              <Menu placement="bottom-end">
                <Flex justifyContent="flex-start">
                  <MenuButton
                    as={Button}
                    color="black"
                    fontSize="md"
                    rightIcon={<Icon as={ChevronDownIcon} boxSize={4} />}
                    variant="ghost"
                    _hover={{ bg: "transparent" }}
                    _expanded={{ bg: "transparent" }}
                    _focus={{ outline: "none" }}
                  >
                    Centre of Excellence
                  </MenuButton>
                </Flex>
                <MenuList fontSize={"sm"} border="1px">
                  <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                    {/* <MenuItem as={RouterLink} to="/servicepage">Accident, Emergency & OPD</MenuItem>
                  <MenuItem as={RouterLink} to="/servicepage">Fertility Centre</MenuItem>
                  <MenuItem as={RouterLink} to="/servicepage">Radiology</MenuItem>
                  <MenuItem as={RouterLink} to="/servicepage">Laboratory & Lab Network</MenuItem> */}
                    <MenuItem as={RouterLink} to="/heart-centre" width="150px">
                      Heart Centre
                    </MenuItem>
                    {/* <MenuItem as={RouterLink} to="/servicepage">Bone Marrow</MenuItem>
                  <MenuItem as={RouterLink} to="/servicepage">Pharmacy</MenuItem>
                  <MenuItem as={RouterLink} to="/servicepage">Digestive Care/Endoscopy</MenuItem> */}
                    <MenuItem as={RouterLink} to="/servicepages/neurocare" width="150px">
                      Neuro Centre
                    </MenuItem>
                    <MenuItem as={RouterLink} to="/servicepages/rns" width="150px" >
                      Rheumatology & Sports Medicine
                    </MenuItem>
                    {/* <MenuItem as={RouterLink} to="/servicepage">Eye Care</MenuItem>
                  <MenuItem as={RouterLink} to="/servicepage">Cancer Care</MenuItem>
                  <MenuItem as={RouterLink} to="/servicepage">Mother & Baby Care</MenuItem>
                  <MenuItem as={RouterLink} to="/servicepage">Skin & Cosmetic Care</MenuItem> */}
                    <MenuItem as={RouterLink} to="/servicepage" width="150px" >
                      Ear Nose & Throat Care
                    </MenuItem>
                    <MenuItem as={RouterLink} to="/servicepages/fertilitycare" width="150px">
                      Fertility Care
                    </MenuItem>
                    <MenuItem as={RouterLink} to="/radiology" width="150px">
                      Radiology & Imaging
                    </MenuItem>
                    <MenuItem as={RouterLink} to="/servicepages/momandbaby" width="150px">
                      Mother & Baby
                    </MenuItem>
                    <MenuItem as={RouterLink} to="/servicepages/cancer" width="150px">
                      Cancer and Blood Disease
                    </MenuItem>
                    <MenuItem as={RouterLink} to="/elitecentre" width="150px">
                      Elite Centre
                    </MenuItem>
                    {/* <MenuItem as={RouterLink} to="/servicepages/cosmetic" width="150px">
                            Skin Care & Cosmetic Centre
                          </MenuItem>
                          <MenuItem as={RouterLink} to="/servicepages/dental" width="150px">
                            Dental Unit
                          </MenuItem>
                          <MenuItem as={RouterLink} to="/servicepages/diabetes" width="150px">
                            Diabetes Centre
                          </MenuItem> */}

                  </Grid>
                </MenuList>
              </Menu>

              <Menu placement="bottom-end">
                <Flex justifyContent="flex-start">
                  <MenuButton
                    as={Button}
                    color="black"
                    fontSize="md"
                    rightIcon={<Icon as={ChevronDownIcon} boxSize={4} />}
                    variant="ghost"
                    _hover={{ bg: "transparent" }}
                    _expanded={{ bg: "transparent" }}
                    _focus={{ outline: "none" }}
                  >
                    Services/Clinics
                  </MenuButton>
                </Flex>
                <MenuList minWidth="150px" border="1px">
                  <Stack direction="column" spacing={4}>

                    <MenuItem as={RouterLink} to="/treatments/surgery">
                      Surgery Informations
                    </MenuItem>

                    <Menu placement="bottom-end">
                      <Flex justifyContent="flex-start">
                        <MenuButton
                          as={Button}
                          color="balck"
                          fontSize="md"
                          rightIcon={<Icon as={ChevronRightIcon} boxSize={4} />}
                          variant="ghost"
                          _hover={{ bg: "transparent" }}
                          _expanded={{ bg: "transparent" }}
                          _focus={{ outline: "none" }}
                          fontWeight={"normal"}
                        >
                          Clinics
                        </MenuButton>
                      </Flex>
                      <MenuList
                        fontSize="sm"
                        border="1px"
                        maxH="250px"
                        overflowY="auto"
                        boxShadow="md"
                      >
                        <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                          <MenuItem as={RouterLink} to="/clinics/physiotherapy">
                            Physiotherapy
                          </MenuItem>
                          <MenuItem as={RouterLink} to="/clinics/eyecare">
                            Eye Care
                          </MenuItem>
                          <MenuItem as={RouterLink} to="/clinics/bloodbank">
                            Blood Bank
                          </MenuItem>
                          <MenuItem as={RouterLink} to="/clinics/nutrition">
                            Nutrition & Dietetics
                          </MenuItem>
                          {/* <MenuItem as={RouterLink} to="/service1/subservice2">Diabetic</MenuItem>
                  <MenuItem as={RouterLink} to="/service2/subservice1">Blood Bank</MenuItem>
                  <MenuItem as={RouterLink} to="/service2/subservice2">Geriatric</MenuItem>
                  <MenuItem as={RouterLink} to="/service2/subservice1">Respiratory</MenuItem>
                  <MenuItem as={RouterLink} to="/service2/subservice2">Immunization</MenuItem>
                  <MenuItem as={RouterLink} to="/service2/subservice2">EMG</MenuItem>
                  <MenuItem as={RouterLink} to="/service2/subservice2">Drug Rehabilitation</MenuItem>
                  <MenuItem as={RouterLink} to="/service2/subservice2">Dental</MenuItem>
                  <MenuItem as={RouterLink} to="/service2/subservice2">Trauma Management</MenuItem>
                  <MenuItem as={RouterLink} to="/service2/subservice2">Breast Care</MenuItem>
                  <MenuItem as={RouterLink} to="/service2/subservice2">Headache</MenuItem>
                  <MenuItem as={RouterLink} to="/service2/subservice2">Speech Therapy</MenuItem>
                  <MenuItem as={RouterLink} to="/service2/subservice2">Health Check</MenuItem>
                  <MenuItem as={RouterLink} to="/service2/subservice2">Memory Clinic</MenuItem>
                  <MenuItem as={RouterLink} to="/service2/subservice2">Stress Management</MenuItem>
                  <MenuItem as={RouterLink} to="/service2/subservice2">Menopause</MenuItem>
                  <MenuItem as={RouterLink} to="/service2/subservice2">Stroke</MenuItem> */}
                        </Grid>
                      </MenuList>
                    </Menu>

                    <MenuItem as={RouterLink} to="/any-payment">
                      Payment Portal
                    </MenuItem>
                  </Stack>
                </MenuList>
              </Menu>
              {/* <Link as={RouterLink} to='/' key='Treatments' style={{ textDecoration: 'none' }}>Treatments</Link> */}
              <Link
                as={RouterLink}
                to="/international"
                key="International Patients"
                ml="15px"
                fontWeight="semibold"
                style={{ textDecoration: "none" }}
              >
                International Patients
              </Link>

              <Menu placement="bottom-end">
                <Flex justifyContent="flex-start">
                  <MenuButton
                    as={Button}
                    color="black"
                    fontSize="md"
                    rightIcon={<Icon as={ChevronDownIcon} boxSize={4} />}
                    variant="ghost"
                    _hover={{ bg: "transparent" }}
                    _expanded={{ bg: "transparent" }}
                    _focus={{ outline: "none" }}
                  >
                    News & Media
                  </MenuButton>
                </Flex>
                <MenuList minWidth="150px" border="1px">
                  <Stack direction="column" spacing={4}>
                    <MenuItem as={RouterLink} to="/" onClick={handleOpenModal}>
                      Media Releases
                    </MenuItem>
                    <MenuItem as={RouterLink} to="/media">
                      Videos
                    </MenuItem>
                    {/* <MenuItem as={RouterLink} to="/promotions">
                    Ongoing Promotions
                  </MenuItem> */}

                    <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="5xl">
                      <ModalOverlay />
                      <ModalContent>
                        {/* <ModalHeader>SPECIAL NOTICE</ModalHeader> */}
                        <ModalCloseButton />
                        <ModalBody>
                          {/* Add your image here */}
                          <Image src={require("../img/we-are-open.png")} alt="Corporate Unit PIC" maxW="100%" maxH="100%" />

                          {/* You can also add additional content or a PDF viewer below the image */}
                          {/* Example: <iframe src="path/to/your/pdf.pdf" width="100%" height="500px" /> */}
                        </ModalBody>
                      </ModalContent>
                    </Modal>

                  </Stack>
                </MenuList>
              </Menu>
              <Link
                as={RouterLink}
                to="/contactus"
                key="Contact us"
                ml="15px"
                fontWeight="semibold"
                style={{ textDecoration: "none" }}
              >
                Contact us
              </Link>
            </Stack>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default Header;
